import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

export default function WebAuditServices() {
  return (
    <Layout insightCategory="web-audit-services">
      <SEO title="Web Audit Services" />
      <div className="bg-plightpink">
        <PurpleHeroSection
          titleFirst={"Web Audit "}
          titleSecond={"Services"}
          subtitle={
            "Full, customized website review – not a cookie-cutter template approach "
          }
          content={`Our Website Audit Service can save your company thousands or millions by exposing hidden issues that prevent you from accomplishing your strategic objectives.`}
          image={require("../assets/audit.svg")}
          // imageComponent={
          //   <div
          //     data-aos="fade-left"
          //     className="ml-auto w-full md:w-6/12 max-h-screen pt-10 pb-20 "
          //   >
          //     <img
          //       className="md:w-8/12 lg:w-1/2 lg:py-16 mx-auto"
          //       alt={""}
          //       src={require("../assets/audit.svg")}
          //     />
          //   </div>
          // }
        />
        <h4
          data-aos="fade-left"
          className="text-3xl md:text-4xl font-black text-left md:text-center text-pgray mt-4"
        >
          Why You Need to Audit your Website
        </h4>
        <h6 data-aos="zoom-in" className="text-pgray text-center mt-2">
          You've invested so much money or countless hours to launch your new
          website. But once the excitement wears off, your website falls off the
          radar. <br /> Then, it stops doing what it was supposed to do. Or
          maybe it never did! You experience sluggish load times and above all a
          poor search engine visibility.
          <br /> Inconsistent branding. Disappointing response rate. Cryptic
          error messages. Security breaches. And so on.
        </h6>
        <OAP />
      </div>
      <OtherServices />
    </Layout>
  )
}
const OAPData = [
  {
    title: "Goal",
    content:
      "Comprehensive discussion to review your website goals, SEO history, competitor reviews, and a summary of how you would like to improve your website performance.",
  },
  {
    title: "User Experience",
    content:
      "Review your website or application in detail, noting how a first-time visitor experiences your website – the positives, the negatives, and everything in between.",
  },
  {
    title: "Seo & Technical Audit",
    content:
      "A diagnostic look “under the hood” of your site programming and performance to get a complete overview of your online presence.",
  },
  {
    title: "Make Recommendations",
    content:
      "Follow-up discussion to walk through findings and recommended action plan",
  },
]
const OAP = () => (
  <CustomHowWeDoIt
    bg={false}
    bgColor={false}
    title="Our audit processes"
    data={OAPData}
  />
)
